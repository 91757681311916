import { getItem, removeItem, setItem } from '../../utils/storage';

type LandingPageEventData = {
  name?: string;
  email?: string;
  pageSet?: string;
  focusType?: string;
  phone?: string;
  performanceType?: string;
  instagram?: string;
  showCount?: string;
  representation?: string;
  calendlyEventUri?: string;
  calendlyInviteeUri?: string;
};

const KEY_EVENT_DATA = 'lb_landing_page_event_data';

export function storeLandingPageEventData(data: LandingPageEventData): void {
  setItem<LandingPageEventData>(KEY_EVENT_DATA, data);
}

export function getLandingPageEventData(): LandingPageEventData | null {
  return getItem<LandingPageEventData>(KEY_EVENT_DATA);
}

export function clearLandingPageEventData(): void {
  removeItem(KEY_EVENT_DATA);
}
