import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import imgBackground from 'images/focus/success/success-background.png';
import { Background } from 'components/shared';
import MailchimpBanner from '../../components/MailchimpSignup/MailchimpBanner';
import LBLogo from '../../images/focus/lb-logo-white.svg';
import { LogoContainer } from '../../components/MarketingSets/SharedStyles';
import NavigationButtonV2 from '../../components/NavigationButtonV2/NavigationButtonV2';
import useQueryParamsAsSingleValues from '../../hooks/useQueryParamsAsSingleValues';
import { PageProps } from 'gatsby';
import { LANDING_PAGE_FOCUS, MAILCHIMP_URL } from 'utils/constants';
import Footer from 'components/Footer/Footer';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { dataLayerPush, EVENT_RR_LEAD_FORM_SUBMIT } from '../../utils/analytics';
import { clearLandingPageEventData, getLandingPageEventData } from '../../components/MarketingSets/utils';
import { Helmet } from 'react-helmet';

const MainContentWrapper = styled('div')`
  background-size: cover;
  color: white;
  padding: 3.8125rem 0.5rem 4.3125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 75%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3.375rem 1.5rem;
  }
`;

const StyledNavbar = styled('div')`
  display: flex;
  height: 65px;
  margin-bottom: 4rem;
`;

const SuccessText = styled('p')`
  font-size: 1.5rem;
`;

// @ts-ignore
const NavLink = styled(NavigationButtonV2)`
  margin-left: 3.4375rem;
  margin-top: 0.2rem;
  bottom: 8px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.white};
  }
`;

const SuccessTitle = styled('span')`
  font-family: 'Sora', sans-serif;
  font-size: 4.625rem;
  font-weight: bold;
`;

const homeUrlMap = {
  [LANDING_PAGE_FOCUS.General]: '/',
  [LANDING_PAGE_FOCUS.Comedian]: '/landing-page/comedian',
  [LANDING_PAGE_FOCUS.Musician]: '/landing-page/musician',
  [LANDING_PAGE_FOCUS.Speaker]: '/landing-page/speaker',
};

const formTypeMap = {
  [LANDING_PAGE_FOCUS.General]: 'other',
  [LANDING_PAGE_FOCUS.Comedian]: 'comedian',
  [LANDING_PAGE_FOCUS.Musician]: 'musician',
  [LANDING_PAGE_FOCUS.Speaker]: 'speaker',
};

const SuccessPage = ({ location }: PageProps) => {
  const { source } = useQueryParamsAsSingleValues(location.search);

  const homeLink = useMemo(
    () => homeUrlMap[source as LANDING_PAGE_FOCUS] || homeUrlMap[LANDING_PAGE_FOCUS.General],
    [source]
  );

  useEffect(() => {
    const { name, email, focusType, ...otherData } = getLandingPageEventData() || {};

    dataLayerPush({
      ...otherData,
      event: EVENT_RR_LEAD_FORM_SUBMIT,
      name_full: name,
      email_address: email,
      form_type: formTypeMap[(source || focusType) as LANDING_PAGE_FOCUS] || formTypeMap[LANDING_PAGE_FOCUS.General],
    });

    clearLandingPageEventData();
  }, [source]);

  return (
    <div>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/landing-pages/success/`} />
      </Helmet>
      <Background image={imgBackground}>
        <Container>
          <MainContentWrapper>
            <StyledNavbar>
              <LogoContainer>
                <img alt='livebash logo' src={LBLogo} />
              </LogoContainer>
              <NavLink to={homeLink}>Home</NavLink>
            </StyledNavbar>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={10}>
                <SuccessTitle>Your Consultation Has Been Booked!</SuccessTitle>
                <SuccessText>A Live Bash producer will reach out to you within 48 hours.</SuccessText>
                <SuccessText>
                  {`You will also be receiving an email confirming the details of your booking. We're excited to get you on stage!`}
                </SuccessText>
              </Grid>
            </Grid>
          </MainContentWrapper>
        </Container>
      </Background>
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <MailchimpBanner subscribe={subscribe} status={status} message={message} />
        )}
      />
      <Footer />
    </div>
  );
};

export default SuccessPage;
